<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <span class="text-500 line-height-3 cursor-pointer">Inventario</span>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Carga Inicial</span>
    </li>
  </ul>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="font-medium text-3xl text-900 col-12">
          Cargar Inventario Incial
        </div>
        <div class="formgrid grid">
          <div class="field col-12 md:col-3">
            <h5>Sucursal:</h5>
            <Dropdown
              v-model="sucursalSelect"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
              placeholder="Seleccione una sucursal"
              class="p-inputtext-sm"
            />
          </div>
          <div class="field col-12 md:col-9">
            <h5>Seleccione Archivo:</h5>
            <FileUpload
              id="file"
              accept=".xlsx"
              name="file[]"
              type="file"
              enctype="multipart/form-data"
              :customUpload="true"
              @uploader="onChange"
              label="Cargar Archivo"
              v-tooltip.top="'Importar Archivo Excel para Carga Inicial'"
              chooseLabel="Importar"
              class="p-button-outlined mr-2"
              :disabled="cargandoExcel"
            />
          </div>
          <div class="field col-12 md:col-9">
            <h5>Carga para Paquetes |Paquete_id|Producto_id|Cantidad|:</h5>
            <FileUpload
              id="file"
              accept=".xlsx"
              name="file[]"
              type="file"
              enctype="multipart/form-data"
              :customUpload="true"
              @uploader="onChangePaquete"
              label="Cargar Archivo"
              v-tooltip.top="'Importar Archivo Excel para Paquete'"
              chooseLabel="Importar"
              class="p-button-outlined mr-2"
              :disabled="cargandoExcel"
            />
          </div>
          <div class="field col-12 md:col-9">
            <h5>Carga Inicial de Mercados y Clientes:</h5>
            <FileUpload
              id="file"
              accept=".xlsx"
              name="file[]"
              type="file"
              enctype="multipart/form-data"
              :customUpload="true"
              @uploader="onChangeMercadoCliente"
              label="Cargar Archivo"
              v-tooltip.top="'Importar Archivo Excel para Mercados y Clientes'"
              chooseLabel="Importar"
              class="p-button-outlined mr-2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SucursalService from "@/service/SucursalService";
import ProductService from "@/service/ProductService";
import ClienteService from "@/service/ClienteService";
export default {
  data() {
    return {
      sucursales: [],
      sucursalSelect: null,
      cargandoExcel: false,
    };
  },
  sucursalService: null,
  productoService: null,
  clienteService: null,
  created() {
    this.sucursalService = new SucursalService();
    this.productoService = new ProductService();
    this.clienteService = new ClienteService();
  },
  mounted() {
    this.sucursalService.getSucursalesAll().then((response) => {
      this.sucursales = response;
      this.sucursales.forEach((sucursal) => {
        this.sucursalSelect = sucursal.id;
      });
    });
  },
  methods: {
    onChange(event) {
      if (this.sucursalSelect == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar una sucursal",
          life: 3000,
        });
        return;
      }
      let archivo = event.files[0];
      let formData = new FormData();
      formData.append("miarchivo", archivo);
      formData.append("sucursal_para", this.sucursalSelect);
      this.cargandoExcel = true;
      this.productoService.importarProductos(formData).then((response) => {
        if (response.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: response.mensaje,
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.mensaje,
            life: 3000,
          });
        }
        this.cargandoExcel = false;
      });
    },
    onChangePaquete(event) {
      let archivo = event.files[0];
      let formData = new FormData();
      formData.append("miarchivo", archivo);
      this.cargandoExcel = true;
      this.productoService.importarPaquete(formData).then((response) => {
        console.log(response);
        if (response.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: response.mensaje,
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.mensaje,
            life: 3000,
          });
        }
        this.cargandoExcel = false;
      });
    },
    onChangeMercadoCliente(event) {
      let archivo = event.files[0];
      let formData = new FormData();
      formData.append("miarchivo", archivo);
      this.cargandoExcel = true;
      this.clienteService.importarMercadoCliente(formData).then((response) => {
        console.log(response);
        if (response.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: response.mensaje,
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.mensaje,
            life: 3000,
          });
        }
        this.cargandoExcel = false;
      });
    },
  },
};
</script>
